.wysiwyg {
  width: 100%;
  max-width: 100%;

  .fr-box.fr-basic {
    border: solid 2px rgba(188, 204, 220, 0.8);
  }

  .fr-view {
    font-family: "Rubik", sans-serif;
    font-size: 1rem;
    color: red;
  }

  .fr-view .terms{
    font-size: 12px;
  }
  
}

.wysiwyg-primary-btn {
  background: 000;
}