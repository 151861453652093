* {
  box-sizing: border-box;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
}

$blue: rgba(33, 134, 235, 1);
$red: rgba(255, 67, 104, 1);
$border-color: #efefef;
$orange: #f8981c;
$nav-background: rgba(247, 247, 250, 0.6);

dialog::backdrop {
  background: rgba(0, 0, 0, 0.25);
}

html {
  scroll-behavior: smooth;
}

.App {
  display: flex;
  // height: 100vh;
  // min-height: 768px;
  min-width: 1000px;
  overflow-y: hidden;
  position: relative;
}

.home {
  padding: 40px;
  width: 100%;
  height: 100%;
}

.dashboard-margin {
  width: 250px;
}

.dashboard {
  padding: 20px;
  width: calc(100% - 250px);
}

.navbar {
  box-sizing: border-box;
  padding: 20px;
  width: 246px;
  background: $nav-background;
  box-shadow: inset -14px 0 18px -7px rgba(236, 236, 240, 1);
  overflow-y: auto;
  height: 100%;
  position: fixed;
  z-index: 1;
  top: 0;
  left: 0;
  overflow-x: hidden;
  padding-top: 20px;

  ul {
    list-style: none;
    padding: 0px;
  }

  li {
    margin: 20px 0px;
    display: block;
  }

  i {
    margin-right: 20px;
  }

  .navlink {
    font-size: 18px;
    font-weight: 600;
    color: #929398;
    text-decoration: none;
    cursor: pointer;
  }
  .navlink:hover {
    opacity: 0.5;
  }
  .navlink-active {
    color: blue;
  }
  .heading {
    font-size: 14px;
    color: #b2b3b6;
    text-transform: uppercase;
  }
  .nav-section {
    margin: 30px 0px;
  }
  .avatar {
    grid-row-start: 2;
    grid-row-end: 3;
  }
  .login-form form {
    margin: 25px 0px;
  }
}

.input-cntr {
  margin: 30px 10px 30px 0;
  min-width: 80px;
  max-width: 500px;
  width: 100%;
  position: relative;
  .label {
    font-size: 14px;
    font-weight: 600;
    margin-bottom: 10px;
  }
  .control {
    box-sizing: border-box;
    width: 100%;
    padding: 9px;
    border: solid 2px rgba(188, 204, 220, 0.8);
    border-radius: 6px;
    font-size: 15px;
    color: #000;
  }
  .control:hover {
    border: solid 2px rgba(131, 142, 154, 0.6);
  }
  .control:focus {
    border: solid 2px #2186eb;
    border-radius: 6px;
    outline-color: #2186eb;
    outline: none;
  }
  .is-invalid {
    border: solid 2px #ef4e4e;
  }
  .invalid-feedback {
    display: block;
    margin-top: 5px;
    color: #ef4e4e;
    font-weight: 600;
    font-size: 11px;
    position: absolute;
    i {
      margin: 0 5px 0 0;
    }
  }
  .helper-text {
    display: block;
    min-width: 100%;
    margin-top: 10px;
    color: #929398;
    i {
      margin: 0 5px 0 0;
    }
  }

  textarea {
    resize: vertical;
  }
}
.input-md {
  max-width: 400px;
}
.input-sm {
  max-width: 200px;
}
.cat-input {
  width: 100%;
  .control {
    padding: 3px 5px;
    border: 1px solid rgba(188, 204, 220, 0.8);
  }
  .control:hover {
    border: 1px solid rgba(131, 142, 154, 0.6);
  }
  .control:focus {
    border: 1px solid #2186eb;
    border-radius: 6px;
    outline-color: #2186eb;
    outline: none;
  }
}



.business-description, .discount-description {
  color: #fff;
  text-transform: uppercase;
  padding-right: 10px;
  background-color: #1e1d1d;
}

.slide-description {
  font-weight: 700;
  line-height: 1.5;
  font-size: clamp(18px, 1.41vw, 1.41vw);
  color: rgb(130, 130, 130);
}

.homepage-header {
  font-size: 38px;
  text-transform: uppercase;
  line-height: 42px;
  color: #f2f2f2;
}

.homepage-description {
  color: white;
  font-size: 10px;
  font-weight: 600;
  line-height: 14px;
}


.about-us-header {
  color: #000000;
  font-weight: 600;
  font-style: normal;
  text-align: center;
  margin: 0;
  font-size: 1.875vw;
  line-height: 1.2em;
}

.about-us-text {
  color: #000000;
  font-style: normal;
  font-weight: normal;
  text-align: center;
  text-transform: uppercase;
  font-size: 0.703125vw;
}

// Global button disabled style
.is-disabled {
  opacity: 0.3;
}

// INPUT TEST PAGE
.margin-lg {
  margin: 50px 0px;
}

.dashboard-header {
  display: flex;
  justify-content: space-between;
  align-items: center;

  h1 {
    display: block;
  }
}

.list-group {
  max-width: 1000px;
  min-height: 520px;
  border: 1px solid $border-color;
  border-radius: 5px;
  ul {
    list-style: none;
    font-size: 14px;
    margin: 0;
    padding: 0;

    li {
      padding: 12px 20px;
      border-bottom: 1px solid $border-color;

      .button-container {
        display: flex;
        text-align: center;
        padding: 0 !important;

        .button:last-child {
          margin-right: 0;
        }
      }
      .list-inner {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;

        .name {
          width: 30%;
        }
        .email {
          width: 40%;
        }
        .role {
          width: 10%;
        }
        .date {
          width: 10%;
          text-align: center;
        }
      }
    }

    li:hover {
      background: rgba(247, 247, 250, 0.6);
    }
    li:nth-last-child(1) {
      border: none;
    }
    .subcats-container {
      padding: 10px 0;
      li {
        padding: 5px 0 5px 5px;
        border-bottom: none;
      }
    }
    .header {
      padding: 12px 20px;
      border-bottom: 2px solid $border-color;
      text-transform: uppercase;
      color: #000;
      font-weight: 600;
      font-size: 12px;
      background: $nav-background;
      .list-inner {
        display: flex;
        align-items: center;
      }
    }
    .add-subcategory {
      text-transform: uppercase;
      font-size: 11px;
      cursor: pointer;
      letter-spacing: 1px;
      color: $blue;
    }
    .add-subcategory:hover {
      text-decoration: underline;
    }
  }
}

.truncate {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.pagination {
  padding: 0;
  margin: 0;
  ul {
    list-style-type: none;
    padding: 5px 0;
    margin: 5px 0;
    display: flex;
    li {
      font-size: 14px;
      color: #000;
      font-weight: 600;
      min-width: 8px;
      text-align: center;
      border: 2px solid $border-color;
      border-radius: 5px;
      padding: 5px 7px;
      margin: 1px;
      cursor: pointer;
    }
    li:hover {
      font-weight: 600;
      color: $blue;
    }
    .active {
      border: 2px solid $blue;
      font-weight: 600;
    }
  }
}

.spinner {
  width: 60px;
  height: 60px;
  margin: 10px;
}

.cp {
  cursor: pointer;
}

.form-row {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0;
  margin: 20px 0 0 0;
  height: 70px;
  .input-cntr:last-child {
    margin: 0;
  }
}

.react-datepicker-wrapper {
  width: 100%;
}

.breadcrumbs {
  .breadcrumb-item {
    font-size: 13px;
    color: #000;
    font-weight: 600;
    text-transform: capitalize;
    text-decoration: none;
  }
  .breadcrumb-item:hover {
    color: $blue;
  }
}

.flex {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
}

.toggleIsActive {
  text-transform: uppercase;
  font-size: 13px;
  cursor: pointer;
}
.toggleIsActive:hover {
  text-decoration: underline;
}

// Dialog styling
dialog {
  position: relative;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  margin: 0;
  box-sizing: border-box;
  border: 1px solid rgba(131, 142, 154, 0.6);
  box-shadow: 7px 7px 5px 2px rgba(131, 142, 154, 0.6);
  padding: 30px;
  min-width: 1000px;
  min-height: 200px;
  max-height: 80vh;
  overflow-y: auto;

  .closeDialog {
    position: absolute;
    top: 20px;
    right: 20px;
    cursor: pointer;
  }
  .closeDialog:hover {
    color: $red;
  }
}

// icon css
.far {
  margin: 0 5px;
  font-size: 16px;
  color: #929398;
  cursor: pointer;
}
.far:hover {
  color: #2186eb;
}
.icon-delete:hover {
  color: #ef4e4e;
}


.btn_appart {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.error_msg {
  display: block;
  margin: 20px 0;
  color: #ef4e4e;
  font-weight: 500;
  font-size: 13px;
}

.input-cntr {
  .react-datepicker-popper {
    z-index: 999;
  }
}

.btn_container {
  display: flex;
  align-items: center;
}

.shareButton {
  min-width: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 5px;
  height: 25px;
}

.referralCode {
  p {
    span {
      padding: 5px 10px;
      background: #f2f2f2;
      border-radius: 5px;
      color: #4f4f4f;
      &:hover {
        cursor: pointer;
      }
    }
  }
}
