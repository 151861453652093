$blue: rgba(33, 134, 235, 1);
$red: rgba(255, 67, 104, 1);
$border-color: #efefef;
$orange: #f8981c;
$nav-background: rgba(247, 247, 250, 0.6);

.container {
  .inner {
    display: flex;

    .left {
      width: 50%;
      padding-right: 50px;

      .list_controls {
        margin-top: 20px;
        display: flex;
        justify-content: space-between;
        align-items: center;
      }
    }
    .right {
      width: 50%;
    }
  }
}

.totalResults {
  display: block;
  font-size: 13px;
  margin: 10px 0;
}

.list_group {
  max-width: 1000px;
  min-height: 520px;
  border: 1px solid $border-color;
  border-radius: 5px;

  list-style: none;
  font-size: 14px;
  margin: 0;
  padding: 0;

  li {
    padding: 12px 20px;
    border-bottom: 1px solid $border-color;
    cursor: pointer;

    .button-container {
      display: flex;
      text-align: center;
      padding: 0 !important;

      .button:last-child {
        margin-right: 0;
      }
    }
    .list-inner {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: space-between;

      .name {
        width: 30%;
      }
      .email {
        width: 40%;
      }
      .role {
        width: 10%;
      }
    }
  }

  li:hover {
    background: rgba(247, 247, 250, 0.6);
  }
  li:nth-last-child(1) {
    border: none;
  }
  .subcats-container {
    padding: 10px 0;
    li {
      padding: 5px 0 5px 5px;
      border-bottom: none;
    }
  }
  .header {
    padding: 12px 20px;
    border-bottom: 2px solid $border-color;
    text-transform: uppercase;
    color: #000;
    font-weight: 600;
    font-size: 12px;
    background: $nav-background;
    .list-inner {
      display: flex;
      align-items: center;
    }
  }
  .add-subcategory {
    text-transform: uppercase;
    font-size: 11px;
    cursor: pointer;
    letter-spacing: 1px;
    color: $blue;
  }
  .add-subcategory:hover {
    text-decoration: underline;
  }
}

.list_item {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;

  .delete_container {
    span {
      color: $red;
      font-size: 11px;
      text-transform: uppercase;

      &:hover {
        font-weight: 600;
      }
    }
  }
}
